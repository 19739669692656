import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import logo from '../img/logo.png';
import { useAuth } from '../hooks/auth';
import { useToggleDialog } from '../hooks/toggleDialog';

const UserLayout = () => {
  const { pathname } = useLocation();
  const toggleDialog = useToggleDialog();
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <>
      <header className={'frams-header d-flex ' + (toggleDialog.isDialogVisible ? ' dialog-open ' : '')}>
        <h3>{auth.user ? auth.user.username : ''}</h3>
        <img src={logo} alt="" />
        <h3 className="text-right">
          <span
            className="link-action"
            onClick={() =>
              auth.signout(() => {
                navigate('/login');
              })
            }
          >
            <span className="link-icon">
              <i className="fa fa-sign-out-alt"></i>
            </span>
            Logout
          </span>
        </h3>
      </header>
      <section className={'container ' + (toggleDialog.isDialogVisible ? 'dialog-open' : '')}>
        <div className="tab-wrapper d-flex">
          {/* <Link to="./create" className={'tab-btn ' + (pathname === '/create' ? 'active' : '')}>
            New Scene
          </Link> */}
          <Link to="./load" className={'tab-btn ' + (pathname === '/load' ? 'active' : '')}>
            Scenes
          </Link>
          <Link to="./model-manager" className={'tab-btn ' + (pathname === '/model-manager' ? 'active' : '')}>
            Models
          </Link>
          <Link to="./decal-manager" className={'tab-btn ' + (pathname === '/decal-manager' ? 'active' : '')}>
            Decals
          </Link>
          <Link to="./hdri-manager" className={'tab-btn ' + (pathname === '/hdri-manager' ? 'active' : '')}>
            HDRIs
          </Link>
          <Link to="./media-manager" className={'tab-btn ' + (pathname === '/media-manager' ? 'active' : '')}>
            Multimedia Files
          </Link>
          {localStorage.getItem('role') && localStorage.getItem('role') === 'admin' && (
            <Link to="./frames-files" className={'tab-btn ' + (pathname === '/frames-files' ? 'active' : '')}>
              FrameS Files
            </Link>
          )}
        </div>

        <Outlet />
      </section>
    </>
  );
};

export default UserLayout;
