import { useEffect, useState } from 'react';
import './DuplicateScene.css';

const DuplicateSceneModal = ({ handleOk, handleCancel, fileName }) => {
  const [newName, setNewName] = useState('');
  useEffect(() => {
    if (newName === '') {
      setNewName(fileName + '1');
    }
  });
  return (
    <>
      <div
        role="dialog"
        className="ui-dialog ui-corner-all ui-widget ui-widget-content ui-front ui-dialog-buttons  ui-resizable"
        aria-describedby="dialog-form"
        aria-labelledby="ui-id-1"
      >
        <div className="ui-dialog-titlebar ui-corner-all ui-widget-header ui-helper-clearfix ">
          <span id="ui-id-1" className="ui-dialog-title">
            Duplicate Scene of {fileName}
          </span>
          <button className="ui-button ui-corner-all ui-widget ui-button-icon-only ui-dialog-titlebar-close" onClick={() => handleCancel()}>
            <span className="ui-button-icon ui-icon ui-icon-closethick"></span>
            <span className="ui-button-icon-space"> </span>Close
          </button>
        </div>
        <div id="dialog-form" className="ui-dialog-content ui-widget-content dialog-form">
          <p className="validateTips"></p>
          <form>
            <label>New Scene Name</label>
            <input
              type="text"
              className="text ui-widget-content ui-corner-all"
              value={newName}
              onChange={e => {
                console.log(e.target.value);
                setNewName(e.target.value);
              }}
            />
          </form>
        </div>
        <div className="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
          <div className="ui-dialog-buttonset">
            <button type="button" className="ui-button ui-corner-all ui-widget" onClick={() => handleOk(newName)}>
              Duplicate
            </button>
            <button type="button" className="ui-button ui-corner-all ui-widget" onClick={() => handleCancel()}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="ui-widget-overlay ui-front"></div>
    </>
  );
};
export default DuplicateSceneModal;
