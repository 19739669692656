import { useEffect, useState } from 'react';
import logo from '../../img/logo.png';
import { admin } from '../../services/admin';

export default function Download() {
  const [urlForMac, setUrlForMac] = useState('');
  const [urlForWindows, setUrlForWindows] = useState('');

  let url = 'frames://app';

  useEffect(() => {
    if (!urlForMac) {
      admin.getVersion(
        result => {
          setUrlForMac(result.osx_link);
          setUrlForWindows(result.win_link);
        },
        error => {
          console.log('error:', error);
        }
      );
    }
  });

  const downloadApp = osType => {
    var link = document.createElement('a');
    link.target = '_self';
    link.href = osType === 'mac' ? urlForMac : urlForWindows;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <section className="login-wrapper">
        <div className="login-section text-center">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          <div className="comments">
            <h3 className="text-white normal">Dont’ have Frame S installed ?</h3>

            <div className="login-btn-wrapper d-flex justify-center">
              <button className="download-btn" value="Login" onClick={() => downloadApp('windows')}>
                Download for Windows
              </button>
            </div>
            <div className="login-btn-wrapper d-flex justify-center">
              <button className="download-btn" value="Login" onClick={() => downloadApp('mac')}>
                Download for Mac
              </button>
            </div>
          </div>
          <div className="comments">
            <h3 className="text-white normal">Click the button to proceed with Frame S</h3>
            <div className="login-btn-wrapper d-flex justify-center">
              <a href={url} className="download-btn">
                Launch the software
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="footer-text">Copyright © - Vection Italy S.r.l.</div>
    </>
  );
}
