import { getAPIPrefix } from '../config/APIEndpoints';
export const admin = {
  getVersion(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    fetch(getAPIPrefix() + 'get-version', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not list the files.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not list the files.Please try again!' });
      });
  },
  updateVersion(data, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('id', data.id);
    formData.append('version', data.version);
    formData.append('osx_link', data.osx_link);
    formData.append('win_link', data.win_link);
    formData.append('mandatory', data.mandatory);

    fetch(getAPIPrefix() + 'update-version', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not list the files.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not list the files.Please try again!' });
      });
  },
  getFilesList(cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    fetch(getAPIPrefix() + 'setup-file-list', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          let xmlData = '';
          var request = new XMLHttpRequest();
          request.open('GET', result.url, true);
          request.overrideMimeType('text/xml');
          request.onload = function () {
            if (request.readyState === request.DONE) {
              if (request.status === 200) {
                xmlData = request.responseText;
                cb({ xmlData: xmlData, ...result });
              }
            }
          };
          request.send(null);
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Something went wrong!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Something went wrong!' });
      });
  },
  getPresignUrl(file_name, cb, errorCb) {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('file_name', file_name);

    fetch(getAPIPrefix() + 'setup-file-upload', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not upload the file.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not upload the file.Please try again!' });
      });
  }
};
