import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UserLayout from './UserLayout';
import NoPage from './pages/NoPage';
import SignUp from './pages/SignUp';
import ConfirmEmail from './pages/ConfirmEmail';
import CreateScene from './pages/CreateScene';
import ModelManager from './pages/ModelManager';
import DecalManager from './pages/DecalManager';
import Login from './pages/Login';
import SceneList from './pages/SceneList';
import HDRIManager from './pages/HDRIManager';
import FileManager from './pages/FileManager';
import PrivateRoute from './PrivateRoute';
import '../css/theme.css';
import '../css/custom.css';
import { ProvideAuth } from '../hooks/auth';
import { ProvideRegister } from '../hooks/register';
import { ProvideToggleDialog } from '../hooks/toggleDialog';
import Download from './pages/Download';
import EditScene from './pages/EditScene';
import AdminManager from './pages/AdminManager';

export default function App() {
  return (
    <ProvideAuth>
      <ProvideRegister>
        <ProvideToggleDialog>
          <BrowserRouter>
            <Routes>
              <Route index element={<Login />} />
              <Route path="login" element={<Login />} />
              <Route element={<UserLayout />}>
                <Route element={<PrivateRoute />}>
                  <Route path="create" element={<CreateScene />} />
                  <Route path="scene/:name" element={<EditScene />} />
                  <Route path="load" element={<SceneList />} />
                  <Route path="model-manager" element={<ModelManager />} />
                  <Route path="decal-manager" element={<DecalManager />} />
                  <Route path="hdri-manager" element={<HDRIManager />} />
                  <Route path="media-manager" element={<FileManager />} />
                  <Route path="frames-files" element={<AdminManager />} />
                </Route>
              </Route>

              <Route path="download" restricted={true} element={<Download />} />
              <Route path="signup" restricted={true} element={<SignUp />} />
              <Route path="confirm-email" restricted={true} element={<ConfirmEmail />} />
            </Routes>
          </BrowserRouter>
        </ProvideToggleDialog>
      </ProvideRegister>
    </ProvideAuth>
  );
}
