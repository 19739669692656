import { useState } from 'react';
import logo from '../../img/logo.png';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

const Login = () => {
  let auth = useAuth();
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [user, setUser] = useState({ username: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);

  if (localStorage.getItem('token')) {
    return <Navigate to={'/load'} />;
  }

  const onSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    auth.signin(
      user.username,
      user.password,
      success => {
        navigate('/load');
        setIsLoading(false);
      },
      error => {
        setError(error.message);
        setIsLoading(false);
      }
    );
  };
  return (
    <>
      {isLoading && <div className="loading">Loading&#8230;</div>}
      <section className="login-wrapper">
        <div className="login-section text-center">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="container messages inbox">
            <div className="text-error">{error}</div> <br />
          </div>
          <form className="input-from" method="post" onSubmit={onSubmit}>
            <div className="custom-input-form-control">
              <input className="custom-input" type="text" placeholder="Username" required={true} name="username" onChange={e => setUser({ ...user, username: e.target.value })} />
            </div>
            <div className="custom-input-form-control">
              <input
                className="custom-input"
                type="password"
                placeholder="Password"
                required={true}
                name="password"
                onChange={e => setUser({ ...user, password: e.target.value })}
              />
            </div>
            <div className="comments">
              <h3 className="text-white normal">
                If you've lost your username or password contact your referent <br />
                or write us by Contact page on our website.
              </h3>
            </div>
            <div className="login-btn-wrapper d-flex justify-center">
              <button type="submit" className="login-btn" value="Login">
                Login
              </button>
            </div>
          </form>
          <div className="comments">
            <Link className="text-white text-underline" to="/signup">
              Sign Up
            </Link>
          </div>
        </div>
      </section>
      <div className="footer-text">Copyright © - Vection Italy S.r.l.</div>
    </>
  );
};

export default Login;
