const DeleteConfirmationModal = ({ onDeleteReject, deleteFile }) => {
  return (
    <div className="dialog">
      <div className="dialog-message">Are you sure you want to delete this element?</div>
      <div className="dialog-actions">
        <button className="action-cancel" onClick={() => onDeleteReject()}>
          CANCEL
        </button>
        <button className="action-ok" onClick={() => deleteFile()}>
          OK
        </button>
      </div>
    </div>
  );
};
export default DeleteConfirmationModal;
