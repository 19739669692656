import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import XMLParser from 'react-xml-parser';
import { s3File } from '../../services/s3File';
import { scene } from '../../services/scene';
import Modal from '../modals/Modal';
import DuplicateSceneModal from '../modals/DuplicateSceneModal';
import deleteIcon from '../../img/delete-icon.png';
import copyIcon from '../../img/copy-icon.png';
import loadIcon from '../../img/load-icon.png';
import editIcon from '../../img/edit-icon.png';
import addIcon from '../../img/add-icon.png';

const SceneList = () => {
  const [fileNames, setFileNames] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [modal, setModal] = useState(null);

  const _loadScene = fileName => {
    setShowLoader(true);
    let SuccessModal = (
      <Modal
        message={'Scene loaded successfully.'}
        handleOk={() => {
          setModal(null);
        }}
      />
    );

    let ErrorModal = (
      <Modal
        message={'Scene loading failed.'}
        handleOk={() => {
          setModal(null);
        }}
      />
    );

    scene.load(
      'scene',
      fileName,
      success => {
        setShowLoader(false);
        setModal(SuccessModal);
      },
      error => {
        setShowLoader(false);
        setModal(ErrorModal);
      }
    );
  };

  const getLoadSceneConfirmation = fileName => {
    let modal = (
      <Modal
        message={'Are you sure you want to load this scene?'}
        handleOk={() => {
          _loadScene(fileName);
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
        fileName={fileName}
      />
    );
    setModal(modal);
  };

  const _deleteModal = name => {
    setShowLoader(true);
    scene.delete(
      'scene',
      name,
      response => {
        if (response.success) {
          _listFiles();
        } else {
          setShowLoader(false);
        }
      },
      error => {
        setShowLoader(false);
        alert(error.message);
      }
    );
  };

  const getDeleteConfirmation = name => {
    let modal = (
      <Modal
        message={'Are you sure you want to delete this element?'}
        handleOk={() => {
          _deleteModal(name);
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
        fileName={name}
      />
    );
    setModal(modal);
  };

  const _parseXML = ({ xmlData }) => {
    var xml = new XMLParser().parseFromString(xmlData);
    let fileNames = [];
    let prefix = '';
    if (xml) {
      xml.children.forEach((element, index) => {
        if (element.name === 'Prefix') {
          prefix = element.value;
        }
        if (element.name === 'Contents') {
          let tempName = element.children[0]['value'];
          tempName = tempName.replace(prefix + '/', '');
          let tempNameSplit = tempName.split('/');
          let sceneName = tempNameSplit[0];
          if (sceneName !== '' && !fileNames.includes(sceneName)) {
            fileNames.push(sceneName);
          }
        }
      });
    }
    if (fileNames) {
      let sortedFileNames = fileNames.sort(function (a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      setFileNames(sortedFileNames);
    }

    setShowLoader(false);
  };

  const _listFiles = () => {
    setShowLoader(true);
    setIsLoading(true);
    s3File.getList(
      'scene',
      result => {
        _parseXML({ ...result });
      },
      error => {
        setFileNames([]);
        setShowLoader(false);
      }
    );
  };

  const _duplicateScene = (oldSceneName, newSceneName) => {
    setShowLoader(true);
    scene.copy(
      'scene',
      oldSceneName,
      newSceneName,
      success => {
        _listFiles();
      },
      error => {
        setErrorMessage(error.message ? error.message : 'Could not load the scene.Try again!');
        setTimeout(() => {
          setErrorMessage(null);
        }, 5000);
        setShowLoader(false);
      }
    );
  };

  const createDuplicateScene = fileName => {
    let modal = (
      <DuplicateSceneModal
        handleOk={newFileName => {
          _duplicateScene(fileName, newFileName);
          setModal(null);
        }}
        handleCancel={() => {
          setModal(null);
        }}
        fileName={fileName}
      />
    );
    setModal(modal);
  };

  useEffect(() => {
    if (!isLoading) {
      _listFiles();
    }
  });

  return (
    <>
      {modal}

      <div className="tab-body">
        {showLoader && <div className="loading">Loading&#8230;</div>}

        <div className="create-scene">
          <Link to={'/create'}>
            <div className="load-scene-heading" id="file_drop_target">
              <h3 className="normal d-flex file">
                ADD SCENE
                <img src={addIcon} width="40px" alt="" />
              </h3>
            </div>
          </Link>
          <div className="load-table">
            <div className="create-scene-msg error-message queue-status float-unset">{errorMessage}</div>
            <table border="0" cellSpacing="0" cellPadding="0" width="100%" id="table">
              <thead>
                <tr>
                  <th>Scene</th>
                  <th colSpan="4" style={{ textAlign: 'center' }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody id="list">
                <tr className="spacer"></tr>
                {showLoader && fileNames === null && (
                  <tr key="no_data">
                    <td colSpan="5" className="empty">
                      Loading...
                    </td>
                  </tr>
                )}

                {!showLoader && fileNames && fileNames.length === 0 ? (
                  <tr key="no_data">
                    <td colSpan="5" className="empty">
                      There are no saved scenes
                    </td>
                  </tr>
                ) : (
                  fileNames &&
                  fileNames.map((fileName, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr key={'spacer_' + (index + 1)} className="spacer"></tr>
                        <tr key={'data_' + (index + 1)}>
                          <td width="30%" className="td-scene-filename">
                            <span>{fileName}</span>
                          </td>
                          <td width="10%" align="left">
                            <span className="link-action" onClick={() => getLoadSceneConfirmation(fileName)}>
                              <div className="content-middle">
                                <img src={loadIcon} alt="" /> Load
                              </div>
                            </span>
                          </td>
                          <td width="10%" valign="middle">
                            <Link to={'/scene/' + fileName}>
                              <div className="content-middle">
                                <img height="41px" width="41px" src={editIcon} alt="" /> Edit
                              </div>
                            </Link>
                          </td>
                          <td width="10%" valign="middle">
                            <span href="#" className="link-action duplicateScene" onClick={() => createDuplicateScene(fileName)}>
                              <div className="content-middle">
                                <img height="41px" width="41px" src={copyIcon} alt="" /> Copy
                              </div>
                            </span>
                          </td>
                          <td width="10%">
                            <span
                              className="link-action delete"
                              onClick={() => {
                                getDeleteConfirmation(fileName);
                              }}
                            >
                              <div className="content-middle">
                                <img src={deleteIcon} alt="" /> Delete
                              </div>
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SceneList;
