import { getAPIEndpoint } from '../config/APIEndpoints';
export const scene = {
  load(modelName, sceneName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'load');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('sceneName', sceneName);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not load the scene.Try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not load the scene.Try again!' });
      });
  },
  copy(modelName, oldSceneName, newSceneName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'copy');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('oldSceneName', oldSceneName);
    formData.append('newSceneName', newSceneName);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not load the scene.Try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not load the scene.Try again!' });
      });
  },
  delete(modelName, directoryName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'directory-delete');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', directoryName);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          cb(result);
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not delete the file.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not delete the file.Please try again!' });
      });
  },
  create(modelName, sceneName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'add');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', sceneName);
    formData.append('position', []);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not load the scene.Try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not load the scene.Try again!' });
      });
  },
  update(modelName, sceneName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'update');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', sceneName);
    formData.append('position', []);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not load the scene.Try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not load the scene.Try again!' });
      });
  },
  detail(sceneName, cb, errorCb) {
    const url = getAPIEndpoint('scene', 'get-details');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('scene', sceneName);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not load the scene.Try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not load the scene.Try again!' });
      });
  }
};
