import React, { useEffect, useState, useRef } from 'react';
import XMLParser from 'react-xml-parser';
import axios from 'axios';
import moment from 'moment';
import { useToggleDialog } from '../../../hooks/toggleDialog';
import DeleteConfirmationModal from '../../modals/DeleteConfirmationModal';
import { s3File } from '../../../services/s3File';
import { model } from '../../../services/model';
import UploadFileRow from './UploadFileRow';
import deleteIcon from '../../../img/delete-icon.png';
import addIcon from '../../../img/add-icon.png';
import downloadIcon from '../../../img/download-icon.png';

const ManagerPageGenerator = ({ modelName }) => {
  const [files, setFiles] = useState(null);
  const [filesStatus, setFilesStatus] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);
  const [showUploader, setShowUploader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteFilename, setDeleteFilename] = useState(false);
  const [sortedFileNames, setSortedFileNames] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const fileInputRef = useRef(null);
  const toggleDialog = useToggleDialog();
  let dbListInterval;
  let total = 0;

  useEffect(() => {
    if (!isLoading) {
      _listFiles();
    }
  });

  const _dbListFiles = () => {
    if (window.location.pathname === '/model-manager') {
      let statusData = [];
      model.dbList(
        result => {
          let list = result.list;
          if (list.length !== 0) {
            list.forEach((file, index) => {
              statusData[file['name']] = file['status'];
            });
          }
          setFilesStatus(statusData);
        },
        error => {
          console.log('error', error);
        }
      );
    } else {
      clearInterval(dbListInterval);
    }
  };

  const _parseXML = ({ xmlData, dbList, list }) => {
    let statusData = [];
    if (modelName === 'model') {
      if (list.length === 0) {
        setFiles(null);
        setShowLoader(false);
        return;
      }
      list.forEach((file, index) => {
        statusData[file['name']] = file['status'];
      });
      setFilesStatus(statusData);
    }
    var xml = new XMLParser().parseFromString(xmlData);
    let fileData = {};
    if (xml) {
      xml.children.forEach((element, index) => {
        if (element.name === 'Contents') {
          let fullName = element.children[0]['value'].split('/');
          let lastModified = element.children[1]['value'];
          let formatedLastModified = moment(lastModified).format('MMM D, YYYY h:mm A');
          let sizeInKB = parseFloat(element.children[3]['value'] / 1000).toFixed(1);
          let name = decodeURIComponent(fullName[fullName.length - 1].replace(/\+/g, ' '));
          let size = sizeInKB >= 1000 ? (sizeInKB / 1000).toFixed(1) + ' MB' : sizeInKB + ' KB';
          if (name.length === 0) {
            return true;
          }
          if (modelName === 'model') {
            if (dbList.includes(name)) {
              fileData[name] = { name: name, 'size': size, 'lastModified': formatedLastModified, 'url': '' };
            }
          } else {
            fileData[name] = { name: name, 'size': size, 'lastModified': formatedLastModified, 'url': '' };
          }
        }
      });
    }
    let sortedFileNames = Object.keys(fileData).sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });

    setFiles(fileData);
    setSortedFileNames(sortedFileNames);

    if (window.location.pathname === '/model-manager') {
      dbListInterval = setInterval(() => {
        _dbListFiles();
      }, 5000);
    }

    setShowLoader(false);
  };

  const _listFiles = () => {
    setShowLoader(true);
    setIsLoading(true);
    s3File.getList(
      modelName,
      result => {
        _parseXML({ ...result });
      },
      error => {
        setFiles(null);
        setShowLoader(false);
      }
    );
  };

  const handleUploadChange = e => {
    if (e.target.files) {
      total = 0;
      setUploadFiles(null);
      setShowUploader(true);
      setShowLoader(true);
      let uploadFiles = {};
      const reqFiles = e.target.files;

      Object.keys(reqFiles).map(index => {
        let file = reqFiles[index];
        const CancelToken = axios.CancelToken;
        const CancelTokenSource = CancelToken.source();
        uploadFiles[file['name']] = { file: file, 'CancelTokenSource': CancelTokenSource };
      });

      if (uploadFiles) {
        fileInputRef.current.value = null;
        s3File.getPresignUrl(
          modelName,
          Object.keys(uploadFiles),
          response => {
            response.result.map(respObj => {
              if (respObj.response) {
                if (window.location.pathname === '/model-manager') {
                  uploadFiles[respObj.originalFileName]['presignedUrl'] = respObj.response;
                } else {
                  uploadFiles[respObj.fileName]['presignedUrl'] = respObj.response;
                }
              } else {
                let errorHtml =
                  '<tr><td width="20%" class="td-filename"><span>' +
                  respObj.fileName +
                  '</span> <br /><span class="uploadStatus text-error">' +
                  respObj.error +
                  '</span></td></tr><tr class="spacer"></tr>';
                document.getElementById('error-tbl-body').innerHTML += errorHtml;
              }
            });
            setShowLoader(false);
            setUploadFiles(uploadFiles);
          },
          error => {
            setShowLoader(false);
            alert(error.message);
            setShowUploader(false);
          }
        );
      }
    }
  };

  const _uploadCompleteCallback = (fileName = '', error = '') => {
    total = total + 1;
    if (error) {
      let errorHtml =
        '<tr><td width="20%" class="td-filename"><span>' + fileName + '</span> <br /><span class="uploadStatus text-error">' + error + '</span></td></tr><tr class="spacer"></tr>';
      document.getElementById('error-tbl-body').innerHTML += errorHtml;
    }
    if (total === Object.keys(uploadFiles).length) {
      setShowUploader(false);
      setTimeout(() => {
        _listFiles();
      }, 200);
      setTimeout(() => {
        document.getElementById('error-tbl-body').innerHTML = '';
      }, 5000);
    }
  };

  const _successCallbackForModelManager = async fileName => {
    return await new Promise((resolve, reject) => {
      s3File.dbSave(
        modelName,
        fileName,
        response => {
          resolve(true);
        },
        error => {
          reject(false);
        }
      );
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  const downloadFile = fileName => {
    setShowLoader(true);
    s3File.download(
      modelName,
      fileName,
      data => {
        axios({
          url: data.url,
          method: 'GET',
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          setShowLoader(false);
        });
      },
      error => {
        console.log(error);
        setShowLoader(false);
        alert(error.message);
      }
    );
  };

  const deleteFile = () => {
    setShowConfirmationModal(false);
    toggleDialog.toggle();
    setShowLoader(true);
    s3File.delete(
      modelName,
      deleteFilename,
      success => {
        _listFiles();
      },
      error => {
        console.log(error);
        alert(error.message);
        setShowLoader(false);
      }
    );
  };

  const getConfirmation = fileName => {
    setShowConfirmationModal(true);
    setDeleteFilename(fileName);
    toggleDialog.toggle();
  };

  const onDeleteReject = () => {
    setShowConfirmationModal(false);
    setDeleteFilename(null);
    toggleDialog.toggle();
  };

  return (
    <>
      {showConfirmationModal && <DeleteConfirmationModal onDeleteReject={onDeleteReject} deleteFile={deleteFile} />}

      <div className="tab-body">
        {showLoader && <div className="loading">Loading&#8230;</div>}

        <div className="create-scene">
          <div className="load-scene-heading" id="file_drop_target">
            <form method="post" id="fileForm" onSubmit={handleSubmit}>
              <h3 className="normal">
                <a href="#" className="d-flex file">
                  ADD FILE <img src={addIcon} width="40px" alt="" />
                </a>
              </h3>

              <input type="file" multiple onChange={handleUploadChange} ref={fileInputRef} />
              <div id="top" style={{ display: 'none' }}>
                <input id="dirname" type="text" name="name" />
                <input type="submit" value="New folder" />
              </div>
            </form>
          </div>
          <div className="load-table">
            <table border="0" cellSpacing="0" cellPadding="0" width="100%">
              <tbody id="list">
                {showUploader &&
                  uploadFiles &&
                  Object.keys(uploadFiles).map((fileName, index) => {
                    let file = uploadFiles[fileName];
                    return (
                      <UploadFileRow
                        key={'fileuploadRow_' + index}
                        {...file}
                        _uploadCompleteCallback={_uploadCompleteCallback}
                        _successCallback={modelName === 'model' && _successCallbackForModelManager}
                      />
                    );
                  })}
                <tr className="spacer"></tr>
              </tbody>
            </table>

            <table border="0" cellSpacing="0" cellPadding="0" width="100%">
              <tbody id="error-tbl-body"></tbody>
            </table>

            <table border="0" cellSpacing="0" cellPadding="0" width="100%" id="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Modified</th>
                  {modelName === 'model' && <th>Status</th>}
                  <th colSpan="2" style={{ textAlign: 'center' }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody id="list">
                <tr className="spacer"></tr>
                {showLoader && files === null && (
                  <tr key="no_data">
                    <td colSpan="6" className="empty">
                      Loading...
                    </td>
                  </tr>
                )}

                {!showLoader && files && files.length === 0 ? (
                  <tr key="no_data">
                    <td colSpan="6" className="empty">
                      There are no saved files
                    </td>
                  </tr>
                ) : (
                  sortedFileNames &&
                  sortedFileNames.map((name, index) => {
                    let file = files[name];
                    return (
                      <React.Fragment key={index}>
                        <tr key={'spacer_' + (index + 1)} className="spacer"></tr>
                        <tr key={'data_' + (index + 1)}>
                          <td width="30%" className="td-filename">
                            <span>{file.name}</span>
                          </td>
                          <td data-sort="69549648">
                            <span>{file.size}</span>
                          </td>
                          <td data-sort="1552064359">{file.lastModified}</td>
                          {modelName === 'model' && <td className="text-capitalize">{filesStatus[file.name]}</td>}
                          <td>
                            <span
                              className="link-action download"
                              onClick={() => {
                                downloadFile(file.name);
                              }}
                            >
                              <div className="content-middle">
                                <img src={downloadIcon} alt="" /> Download
                              </div>
                            </span>
                          </td>
                          <td>
                            <span
                              className="link-action delete"
                              onClick={() => {
                                getConfirmation(file.name);
                              }}
                            >
                              <div className="content-middle">
                                <img src={deleteIcon} alt="" /> Delete
                              </div>
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerPageGenerator;
