import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

export default function PrivateRoute({ children, ...rest }) {
  let user = localStorage.getItem('token');
  return user ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: '/'
      }}
    />
  );
}
