import React, { useEffect, useState } from 'react';
import axios from 'axios';

const FrameSUpload = ({ CancelTokenSource, file, presignedUrl, error, _uploadCompleteCallback, _successCallback }) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const fnUploadFiles = () => {
    setIsUploading(true);
    if (!presignedUrl) {
      _uploadCompleteCallback();
      return true;
    }
    axios
      .put(presignedUrl, file, {
        cancelToken: CancelTokenSource.token,
        headers: {
          'Content-Type': ''
        },
        onUploadProgress: p => {
          let percentage = parseInt((p.loaded / p.total) * 100);
          setUploadPercentage(percentage);
          if (percentage === 100) {
            setUploadStatus('success');
            _successCallback();
          }
        }
      })
      .then(
        result => {
          _uploadCompleteCallback();
          if (result.status === 200) {
            setUploadPercentage(100);
            setUploadStatus('success');
            _successCallback();
          }
        },
        error => {
          console.log('error:::', error);
          if (axios.isCancel(error)) {
            setUploadStatus('canceled');
          } else {
            setUploadStatus('failed');
          }
          setShowLoader(false);
          _uploadCompleteCallback(file.name, error);
        }
      );
  };

  useEffect(() => {
    if (!isUploading) {
      fnUploadFiles();
    }
  });

  return (
    <>
      {showLoader && <div className="loading">Loading&#8230;</div>}
      {presignedUrl && (
        <>
          <div className="form-group d-inline-flex frames-for-mac">
            <div className="input-frames-progress input-content">
              <span>{process.env.REACT_APP_FRAMES_PREFIX + file.name}</span> &nbsp;&nbsp;
              {!error && uploadPercentage !== 100 && (
                <button
                  className="start-btn frames-upload-cancel"
                  type="button"
                  onClick={() => {
                    CancelTokenSource.cancel('Operation canceled by the user.');
                  }}
                >
                  Cancel
                </button>
              )}
              &nbsp;&nbsp;
              <span className="percentage" style={{ float: 'right', marginRight: '25px' }}>
                {!error && uploadPercentage + '%'}
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <br />
              {error && <span className="uploadStatus text-error">{' - ' + error}</span>}
              <span className="uploadStatus">{uploadStatus}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default FrameSUpload;
