import { useEffect } from 'react';
import { useToggleDialog } from '../../hooks/toggleDialog';

const Modal = ({ message, handleOk = null, handleCancel = null, fileName = '' }) => {
  const toggleDialog = useToggleDialog();
  useEffect(() => {
    if (!toggleDialog.isDialogVisible) {
      toggleDialog.toggle();
    }
  });
  return (
    <div className="dialog">
      <div className="dialog-message">{message}</div>
      <div className="dialog-actions">
        {handleCancel && (
          <button
            className="action-cancel"
            onClick={() => {
              toggleDialog.toggle();
              handleCancel(fileName);
            }}
          >
            CANCEL
          </button>
        )}
        {handleOk && (
          <button
            className="action-ok"
            onClick={() => {
              toggleDialog.toggle();
              handleOk(fileName);
            }}
          >
            OK
          </button>
        )}
      </div>
    </div>
  );
};
export default Modal;
