import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { model } from '../../services/model';
import { scene } from '../../services/scene';
import Modal from '../modals/Modal';

const CreateScene = () => {
  const [sceneName, setSceneName] = useState(null);
  const [modelList, setModelList] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModelListReqSent, setIsModelListReqSent] = useState(false);
  const [isCreateSceneReqSent, setIsCreateSceneReqSent] = useState(false);
  const noOfPositions = localStorage.getItem('max_models') ? parseInt(localStorage.getItem('max_models')) : 0;
  const [positions, setPositions] = useState([...Array(noOfPositions)]);
  let navigate = useNavigate();

  useEffect(() => {
    if (!isModelListReqSent) {
      setIsModelListReqSent(true);
      model.dbList(
        result => {
          if (result.list && result.list.length) {
            let aTemp = [];
            result.list.map((item, index) => {
              if (item.status === 'ready') aTemp.push(item.name);
            });
            setModelList(aTemp);
          }
        },
        error => {}
      );
    }
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (!isCreateSceneReqSent) {
      setShowLoader(true);
      setIsCreateSceneReqSent(true);
      scene.create(
        'scene',
        sceneName,
        result => {
          _resetForm();
          setIsCreateSceneReqSent(false);
          setShowLoader(false);
          setMessage('SUBMITTED TO QUEUE.');
          navigate('/load');
        },
        error => {
          _resetForm();
          setErrorMessage('Could not create Scene. Try Again!');
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
          setIsCreateSceneReqSent(false);
          setShowLoader(false);
        }
      );
    }
  };

  const _resetForm = () => {
    document.getElementById('new-scene-form').reset();
    setSceneName(null);
    setSceneName(null);
    setPositions([...Array(noOfPositions)]);
  };

  return (
    <>
      {showLoader && <div className="loading">Loading&#8230;</div>}
      <div className="tab-body">
        <div className="p-50">
          <form onSubmit={handleSubmit} className="form-narrow" method="post" id="new-scene-form">
            <div className="create-scene">
              <div className="create-scene-msg container messages queue-status">{message}</div>
              <div className="create-scene-msg container error-message queue-status">{errorMessage}</div>
              <div className="form-group d-inline-flex">
                <h3 className="input-heading pr-5">Scene Name</h3>
                <input type="text" name="scene" placeholder="Scene Name" className="input-creat-scene input-content" required={true} onChange={e => setSceneName(e.target.value)} />
                &nbsp;
                <button className="start-btn" type="submit">
                  Start
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateScene;
