import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { model } from '../../services/model';
import { scene } from '../../services/scene';
import Modal from '../modals/Modal';

const EditScene = () => {
  const [sceneName, setSceneName] = useState(null);
  const [modelList, setModelList] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [modal, setModal] = useState(null);
  const [isModelListReqSent, setIsModelListReqSent] = useState(false);
  const [isUpdateSceneReqSent, setisUpdateSceneReqSent] = useState(false);

  let navigate = useNavigate();
  let { name } = useParams();

  let emptyJobModal = (
    <Modal
      message={'Cannot create an empty job!'}
      handleOk={() => {
        setModal(null);
        navigate('/load');
      }}
    />
  );

  let successJobModal = (
    <Modal
      message={'Job executed successfully'}
      handleOk={() => {
        setModal(null);
        navigate('/load');
      }}
    />
  );

  useEffect(() => {
    if (!isModelListReqSent) {
      if (name) setSceneName(name);
      _fetchSceneDetails();
      _fetchModelList();
    }
  });

  const _fetchSceneDetails = () => {
    scene.detail(
      name,
      result => {
        let list = result.objects;
        if (list) {
          // setOldPositions([...list]);
          // setPositions([...list]);
        }
      },
      error => {}
    );
  };

  const _fetchModelList = () => {
    setIsModelListReqSent(true);
    model.dbList(
      result => {
        if (result.list && result.list.length) {
          let aTemp = [];
          result.list.map((item, index) => {
            if (item.status === 'ready') aTemp.push(item.name);
          });
          setModelList(aTemp);
        }
      },
      error => {}
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!isUpdateSceneReqSent) {
      setShowLoader(true);
      setisUpdateSceneReqSent(true);
      scene.update(
        'scene',
        sceneName,
        result => {
          setisUpdateSceneReqSent(false);
          setShowLoader(false);
          setModal(successJobModal);
          setMessage('SUBMITTED TO QUEUE.');
        },
        error => {
          setisUpdateSceneReqSent(false);
          setShowLoader(false);
          setErrorMessage('Could not create Scene. Try Again!');
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        }
      );
    }
  };

  return (
    <>
      {modal}

      {showLoader && <div className="loading">Loading&#8230;</div>}
      <div className="tab-body">
        <div className="p-50">
          <form onSubmit={handleSubmit} className="form-narrow" method="post" id="new-scene-form">
            <div className="create-scene">
              <div className="create-scene-msg container messages queue-status">{message}</div>
              <div className="create-scene-msg container error-message queue-status">{errorMessage}</div>
              <div className="form-group d-inline-flex">
                <h3 className="input-heading pr-5">Scene Name</h3>
                <input
                  type="text"
                  name="scene"
                  placeholder="Scene Name"
                  className="input-creat-scene input-content"
                  required={true}
                  onChange={e => setSceneName(e.target.value)}
                  value={sceneName ? sceneName : ''}
                />
                &nbsp;
                <button className="start-btn" type="submit">
                  Start
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditScene;
