import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRegister } from '../../hooks/register';

export default function ConfirmEmailPage() {
  let navigate = useNavigate();
  let register = useRegister();
  let [searchParams, setSearchParams] = useSearchParams();
  let validation_token = searchParams.get('validation_token');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      verifyEmail();
    }
  });
  const verifyEmail = () => {
    setIsLoading(true);
    register.verifyEmail(
      validation_token,
      data => {
        navigate('/login');
      },
      error => {
        navigate('/signup');
      }
    );
  };

  return <div className="loading">Loading&#8230;</div>;
}
