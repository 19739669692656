import { getAPIEndpoint } from '../config/APIEndpoints';
export const s3File = {
  getList(modelName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'list');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          let xmlData = '';
          var request = new XMLHttpRequest();
          request.open('GET', result.url, true);
          request.overrideMimeType('text/xml');
          request.onload = function () {
            if (request.readyState === request.DONE) {
              if (request.status === 200) {
                xmlData = request.responseText;
                cb({ xmlData: xmlData, ...result });
              }
            }
          };
          request.send(null);
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Something went wrong!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Something went wrong!' });
      });
  },
  getPresignUrl(modelName, fileNames, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'upload');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('files', fileNames);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          if (result.success === false) {
            errorCb({ message: result.message });
          } else {
            cb(result);
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not upload the file.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not upload the file.Please try again!' });
      });
  },
  delete(modelName, fileName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'delete');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('file_name', fileName);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          cb(result);
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not delete the file.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not delete the file.Please try again!' });
      });
  },
  download(modelName, fileName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'download');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('file_name', fileName);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          cb(result);
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './login';
          }
          errorCb({ message: 'Could not download the file.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not download the file.Please try again!' });
      });
  },
  dbSave(modelName, fileName, cb, errorCb) {
    const url = getAPIEndpoint(modelName, 'save');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('fileName', fileName);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          cb(result);
        },
        error => {
          errorCb({ message: 'Could not save the file in db.Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not save the file in db.Please try again!' });
      });
  }
};
