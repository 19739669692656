import axios from 'axios';
import XMLParser from 'react-xml-parser';
import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { admin } from '../../services/admin';
import FrameSUpload from './common/FrameSUpload';

const AdminManager = () => {
  const [files, setFiles] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [versionUpdateMessage, setVersionUpdateMessage] = useState(null);
  const [versionInfo, setVersionInfo] = useState(null);
  const [newVersion, setNewVersion] = useState('');
  const [newUrlForMac, setNewUrlForMac] = useState('');
  const [newUrlForWindows, setNewUrlForWindows] = useState('');
  const [setupfile, setSetupfile] = useState(null);
  const [isUploading, setIsUploading] = useState(null);

  let fileInputRef = useRef();

  useEffect(() => {
    if (!versionInfo) {
      _getVersionDetails();
    }
    if (!files) {
      getList();
    }
  });

  const _getVersionDetails = () => {
    admin.getVersion(
      result => {
        setVersionInfo({ ...result });
        setNewVersion(result.version);
        setNewUrlForMac(result.osx_link);
        setNewUrlForWindows(result.win_link);
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const getList = () => {
    admin.getFilesList(
      result => {
        _parseXML({ ...result });
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const _parseXML = ({ xmlData, dbList, list }) => {
    var xml = new XMLParser().parseFromString(xmlData);
    let fileData = [];
    if (xml) {
      xml.children.forEach((element, index) => {
        if (element.name === 'Contents') {
          let fullName = element.children[0]['value'].split('/');
          let lastModified = element.children[1]['value'];
          let formatedLastModified = moment(lastModified).format('MMM D, YYYY h:mm A');
          let sizeInKB = parseFloat(element.children[3]['value'] / 1000).toFixed(1);
          let name = fullName[fullName.length - 1];
          // let name = decodeURIComponent(fullName[fullName.length - 1].replace(/\+./g, ' '));
          let size = sizeInKB >= 1000 ? (sizeInKB / 1000).toFixed(1) + ' MB' : sizeInKB + ' KB';
          if (name.length === 0) {
            return true;
          }
          fileData.push({ name: name, 'size': size, 'lastModified': formatedLastModified, 'url': '' });
        }
      });
    }
    setFiles(fileData);
    setShowLoader(false);
  };

  const _successCallback = () => {
    setSetupfile(null);
    getList();
  };

  const handleFileSubmit = e => {
    e.preventDefault();
    if (!isUploading && e.target[0].files) {
      setIsUploading(true);
      let file = e.target[0].files[0];
      fileInputRef.current.value = null;
      admin.getPresignUrl(
        file['name'],
        result => {
          const CancelToken = axios.CancelToken;
          const CancelTokenSource = CancelToken.source();
          setSetupfile({ file: file, 'CancelTokenSource': CancelTokenSource, presignedUrl: result.url });
          setIsUploading(false);
        },
        error => {
          console.log('error:', error);
          setIsUploading(false);
        }
      );
    }
  };

  const handleVersionSubmit = e => {
    e.preventDefault();
    let versionData = {
      ...versionInfo,
      version: newVersion,
      osx_link: newUrlForMac,
      win_link: newUrlForWindows
    };
    admin.updateVersion(
      versionData,
      result => {
        setVersionUpdateMessage('Version updated successfully!');
        _getVersionDetails();
        setTimeout(() => {
          setVersionUpdateMessage(null);
        }, 5000);
        setVersionInfo({ ...result });
      },
      error => {
        alert('Error. Could not update version details.');
        console.log('error:', error);
      }
    );
  };

  return (
    <>
      {showLoader && <div className="loading">Loading&#8230;</div>}
      <div className="tab-body">
        <div className="p-50">
          <form encType="multipart/form-data" onSubmit={handleFileSubmit} className="form-narrow" method="post" id="frames-upload-form">
            <div className="create-scene">
              <div className="form-group d-inline-flex frames-for-mac">
                <input type="file" name="setupfile" placeholder="select file" className="input-frames input-content" required={true} multiple={false} ref={fileInputRef} />
              </div>
              <br />
              {setupfile && <FrameSUpload key={'setupfile'} {...setupfile} _uploadCompleteCallback={() => {}} _successCallback={() => _successCallback()} />}
            </div>

            <div className="form-group d-inline-flex frames-upload-submit d-flex">
              <input type="submit" className="start-btn" value="Upload" />
            </div>
          </form>
        </div>
        <div className="load-table">
          <table border="0" cellSpacing="0" cellPadding="0" width="100%" id="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Size</th>
                <th>Modified</th>
              </tr>
            </thead>
            <tbody>
              <tr className="spacer"></tr>
              {showLoader && files === null && (
                <tr key="no_data">
                  <td colSpan="6" className="empty">
                    Loading...
                  </td>
                </tr>
              )}
              {!showLoader && files && files.length === 0 ? (
                <tr key="no_data">
                  <td colSpan="6" className="empty">
                    There are no saved files
                  </td>
                </tr>
              ) : (
                files &&
                files.map((file, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr key={'spacer_' + (index + 1)} className="spacer"></tr>
                      <tr key={'data_' + (index + 1)}>
                        <td width="60%" className="td-filename">
                          <span>{process.env.REACT_APP_FRAMES_PREFIX + file.name}</span>
                        </td>
                        <td data-sort="69549648">
                          <span>{file.size}</span>
                        </td>
                        <td data-sort="1552064359">{file.lastModified}</td>
                      </tr>
                    </React.Fragment>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="load-table">
          <table border="0" cellSpacing="0" cellPadding="0" width="100%" id="table">
            <thead>
              <tr>
                <th>FrameS Version Update</th>
                <th style={{ textAlign: 'right' }}>Current Version: {versionInfo ? versionInfo.version : ''}</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="p-50" style={{ paddingTop: '0px' }}>
          <form onSubmit={handleVersionSubmit} className="form-narrow" method="post" id="frames-version-form">
            <div className="create-scene">
              <div className="create-scene-msg container messages queue-status">{versionUpdateMessage}</div>
              <div className="form-group d-inline-flex">
                <h3 className="input-heading pr-5">Version</h3>
                <input
                  type="text"
                  name="version"
                  placeholder="Version"
                  className="input-frames input-content"
                  required={true}
                  value={newVersion}
                  onChange={e => setNewVersion(e.target.value)}
                />
              </div>
              <div className="form-group d-inline-flex">
                <h3 className="input-heading pr-5">FrameS url for Mac</h3>
                <input
                  type="text"
                  name="urlForMac"
                  placeholder="FrameS url for Mac"
                  className="input-frames input-content"
                  required={true}
                  value={newUrlForMac}
                  onChange={e => setNewUrlForMac(e.target.value)}
                />
              </div>
              <br />
              <div className="form-group d-inline-flex frames-for-windows">
                <h3 className="input-heading pr-5">FrameS url for Windows</h3>
                <input
                  type="text"
                  name="urlForWindows"
                  placeholder="FrameS url for Windows"
                  className="input-frames input-content"
                  required={true}
                  value={newUrlForWindows}
                  onChange={e => setNewUrlForWindows(e.target.value)}
                />
              </div>
              <div className="form-group d-inline-flex frames-for-windows d-flex" style={{ width: '200px', paddingTop: '20px', display: 'flex' }}>
                <button className="start-btn" type="submit">
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminManager;
