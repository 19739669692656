import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UploadFileRow = ({ CancelTokenSource, file, presignedUrl, error, _uploadCompleteCallback, _successCallback }) => {
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const fnUploadFiles = () => {
    setIsUploading(true);
    if (!presignedUrl) {
      _uploadCompleteCallback();
      return true;
    }
    axios
      .put(presignedUrl, file, {
        cancelToken: CancelTokenSource.token,
        headers: {
          'Content-Type': ''
        },
        onUploadProgress: p => {
          let percentage = parseInt((p.loaded / p.total) * 100);
          setUploadPercentage(percentage);
          if (percentage === 100) {
            setUploadStatus('success');
          }
        }
      })
      .then(
        result => {
          if (_successCallback) {
            _successCallback(file.name).then(res => {
              _uploadCompleteCallback();
            });
          } else {
            _uploadCompleteCallback();
          }
        },
        error => {
          if (axios.isCancel(error)) {
            setUploadStatus('canceled');
          } else {
            setUploadStatus('failed');
          }
          setShowLoader(false);
          _uploadCompleteCallback(file.name, error);
        }
      );
  };

  useEffect(() => {
    if (!isUploading) {
      fnUploadFiles();
    }
  });

  return (
    <>
      {showLoader && <div className="loading">Loading&#8230;</div>}
      {presignedUrl && (
        <>
          <tr className="spacer"></tr>
          <tr>
            <td width="20%" className="td-filename">
              <span>{file.name}</span> <br />
              <span className="uploadStatus">{uploadStatus}</span>
              {error && <span className="uploadStatus text-error">{' - ' + error}</span>}
            </td>
            <td width="10%" data-sort="69549648">
              <span className="percentage">{!error && uploadPercentage + '%'}</span>
            </td>
            <td width="10%">
              {!error && (
                <button
                  className="start-btn"
                  type="button"
                  onClick={() => {
                    CancelTokenSource.cancel('Operation canceled by the user.');
                  }}
                >
                  Cancel
                </button>
              )}
            </td>
          </tr>
        </>
      )}
    </>
  );
};
export default UploadFileRow;
