import { createContext, useContext, useEffect, useState } from 'react';

const toggleDialogContext = createContext();

export function ProvideToggleDialog({ children }) {
  const toggleDialog = useProvideToggleDialog();
  return (
    <toggleDialogContext.Provider value={toggleDialog}>
      <>{children}</>
    </toggleDialogContext.Provider>
  );
}

export const useToggleDialog = () => {
  return useContext(toggleDialogContext);
};

function useProvideToggleDialog() {
  const [isDialogVisible, setDialogVisible] = useState(false);

  const toggle = () => {
    return setDialogVisible(!isDialogVisible);
  };
  const hideDialog = () => {
    return setDialogVisible(false);
  };
  const showDialog = () => {
    return setDialogVisible(true);
  };

  useEffect(() => {
    setDialogVisible(isDialogVisible);
  }, []);

  return {
    toggle,
    isDialogVisible,
    hideDialog,
    showDialog
  };
}
