import { getAPIEndpoint } from '../config/APIEndpoints';
export const model = {
  dbList(cb, errorCb) {
    const url = getAPIEndpoint('model', 'db-list');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (response.status !== 200) {
          throw response;
        }
        return response.json();
      })
      .then(
        result => {
          cb(result);
        },
        error => {
          errorCb({ message: 'Please try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Please try again!' });
      });
  }
};
